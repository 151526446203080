@import '../../../styles/constants.scss';
@import '../../../styles/functions/rem-calc.scss';

$factWrapperWidth: rem-calc(260px);

.root {
  margin-bottom: rem-calc(90px);
}

.main__title {
  color: $lightGray;
  font-weight: 500;
  font-size: rem-calc(14px);
  text-transform: uppercase;
  margin-bottom: rem-calc(20px);
  cursor: default;
}

.facts__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($factWrapperWidth, 1fr));
  gap: rem-calc(46px) rem-calc(64px);
}

.fact__wrapper {
  width: $factWrapperWidth;
  display: flex;
  flex-direction: column;
  color: $lightGray;
  cursor: default;
}

.fact__title {
  margin-bottom: auto;
  font-size: rem-calc(14px);
  font-weight: bold;
}

.fact__value__wrapper {
  display: flex;
}

.fact__value {
  font-weight: 500;
  font-size: rem-calc(34px);
  font-weight: bold;
}

.fact__badge {
  max-width: rem-calc(42px);
  max-height: rem-calc(24px);
  padding: rem-calc(2px) rem-calc(4px);
  margin-left: rem-calc(6px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue;
  border-radius: rem-calc(5px);
  font-size: rem-calc(14px);
  transform: translateY(50%);
}